import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import Starmap from '../Create/Starmap';

import astronaut from './astronaut.png'

import ReactGA from "react-ga4";

const stripePromise = loadStripe('pk_test_51PLteTRpsh9c304ZQbCEuvJvP9HmoRMOSTohMzv0haRudTd2ZH4SltkpjVLD06BlIT9uh783VpQGGtF9x1YY3qK000SmJDOrcE');

export default function Checkout() {
  ReactGA.send({ hitType: "pageview", page: "/checkout", title: "Checkout" });

  const [clientSecret, setClientSecret] = useState('');
  const [id, setID] = useState('');
  const [email, setEmail] = useState('')

  let products = JSON.parse(localStorage.getItem('map'))
  let subtotal = products.reduce(function(total, product) {
                  return total + (product['quantity'] * prices[product['size']]/100);
                }, 0);
  let shipping = 0
  let tax = subtotal * 0.07
  let total = subtotal + tax

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await fetch('https://server-e4ys6pw2bq-uc.a.run.app/create-payment-intent', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: localStorage.getItem('map'),
        });

        if (!response.ok) {
          throw new Error('Network response was not ok: ', response);
        }

        const data = await response.json();
        console.log('data:  ', data)
        setClientSecret(data.clientSecret);
        setID(data.id)
        total = data.total
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };

    fetchClientSecret();
  }, []);

  const options = {
    clientSecret: clientSecret,
  };

  return (
    <div className="flex flex-col laptop:flex-row laptop:h-screen bg-lightgray">
      <div className="sticky w-3/4">
        <OrderSummary products={products} subtotal={subtotal} tax={tax} total={total} />
      </div>
    <div className="flex flex-col w-11/12 z-10 mx-auto laptop:w-1/4 rounded-lg bg-white shadow-lg">
      {clientSecret ? (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm email={email} setEmail={setEmail} id={id}  />
        </Elements>
      ) : (
        <div className='m-auto py-20 laptop:py-0 animate-pulse duration-1000'>
          <p className='text-center text-lg font-semibold pb-2'>Loading Secure Checkout...</p>
          <img className='w-2/3 mx-auto' src={astronaut} alt='astronaut' />
        </div>
      )}
    </div>
  </div>

  );
}

const prices = {
  "12x16": 3999,
  "18x24": 4999,
  "24x32": 5999,
  "30x40": 6999,
}

function OrderSummary({ products, subtotal, tax, total}) {
  const [activeIndex, setActiveIndex] = useState(0)  

  const ProductsList = () => {
    return(
      <div className=''>
        {
          products.map((product, index) => (
            <div className='flex flex-row justify-between w-full my-2'  key={index} >
              <h2 className='cursor-pointer' style={index === activeIndex ? {textDecoration: 'underline'} : {}} onClick={() => {setActiveIndex(index)}}>{product['quantity']} × Starmap Print {product['size']}</h2>
              <p>${(product['quantity'] * (prices[product['size']]/100)).toFixed(2)}</p>
            </div>
          ))
        }
      </div>
    )
  }
  const Totals = () => {
    return(
      <div>
        <hr className='my-3 border-lightgray' />
        <div className='flex flex-row justify-between w-full my-3'>
          <p className='text-md'>Subtotal</p>
          <p>${subtotal.toFixed(2)}</p>
        </div>
        <div className='flex flex-row justify-between w-full my-3'>
          <p>Shipping</p>
          <p className='font-semibold'>Free!</p>
        </div>
        <div className='flex flex-row justify-between w-full my-3'>
          <p>Tax</p>
          <p>${tax.toFixed(2)}</p>
        </div>
        <hr className='my-3 border-lightgray' />
        <div className='flex flex-row justify-between w-full my-3'>
          <p className='text-lg font-semibold'>Total</p>
          <p className='text-lg font-semibold'>${total.toFixed(2)}</p>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-center items-center h-auto w-screen laptop:w-full">
      <div className='flex flex-col laptop:flex-row mx-auto my-10 laptop:my-0'>
        <div className='order-2 laptop:order-1 w-11/12 laptop:w-[19vw] h-full laptop:h-max-[80vh] laptop:mt-[10vh] mx-auto laptop:mr-2 p-5 bg-white rounded-br-lg rounded-bl-lg laptop:rounded-lg shadow-2xl'>
          <p className="pb-0 text-lg font-medium text-black">Order Summary</p>
          <p className='pt-0 pb-4 text-sm text-gray'>Double check your order!</p>

          <ProductsList />
          <Totals />
        </div>
        <div className='z-10 order-1 laptop:order-2 laptop:ml-2 shadow-xl laptop:shadow-none'>
          <Starmap options={products[activeIndex]} padding={0} shadow={window.innerWidth > 1080 ? true : false} />
        </div>
      </div>
    </div>
  )
}