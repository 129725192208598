function radians(degrees) {
  return degrees * (Math.PI / 180);
}
function dotMtoV(matrice, vector) {
  var product = []

  for(let i = 0; i < matrice.length; i++) {
    var sum = 0
    for(let j = 0; j < matrice[i].length; j++) {
      sum += matrice[i][j] * vector[j]
    }
    product.push(sum)
  }

  return product
}

// Convert geographical coordinates to spherical coordinates
function geoToSpherical(lon, lat) {
  const theta = lon + 180;
  const phi = 90 - lat;
  return [radians(theta), radians(phi)];
}

// Convert spherical coordinates to Cartesian coordinates
function sphericalToCartesian(theta, phi) {
  const r = 1;
  const x = r * Math.sin(phi) * Math.cos(theta);
  const y = r * Math.sin(phi) * Math.sin(theta);
  const z = r * Math.cos(phi);
  return [x, y, z];
}

// Convert Cartesian coordinates to spherical coordinates
function cartesianToSpherical(x, y, z) {
  const r = Math.sqrt(x ** 2 + y ** 2 + z ** 2);

  // Calculate the azimuthal angle theta
  const theta = Math.atan2(y, x);

  // Calculate the polar angle phi
  const phi = Math.acos(z / r);

  return [theta, phi];
}

// Generate the rotation matrix given deltaTheta and deltaPhi
function getRotationMatrix(deltaTheta, deltaPhi) {
  // Rotation matrix around the z-axis
  const R_z = [
    [Math.cos(deltaTheta), -Math.sin(deltaTheta), 0],
    [Math.sin(deltaTheta), Math.cos(deltaTheta), 0],
    [0, 0, 1]
  ];

  // Rotation matrix around the x-axis
  const R_x = [
    [1, 0, 0],
    [0, Math.cos(deltaPhi), -Math.sin(deltaPhi)],
    [0, Math.sin(deltaPhi), Math.cos(deltaPhi)]
  ];

  // Combined rotation matrix
  const R = mathMultiply(R_x, R_z);

  return R;
}

// Matrix multiplication utility function
function mathMultiply(A, B) {
  const result = [];
  for (let i = 0; i < A.length; i++) {
    result[i] = [];
    for (let j = 0; j < B[0].length; j++) {
      let sum = 0;
      for (let k = 0; k < A[0].length; k++) {
        sum += A[i][k] * B[k][j];
      }
      result[i][j] = sum;
    }
  }
  return result;
}

// Calculate the deltas for spherical coordinates
function deltaSpherical(theta, phi) {
  const deltaTheta = 0 - theta;
  const deltaPhi = 0 - phi;
  return [deltaTheta, deltaPhi];
}

// Convert spherical coordinates to SVG coordinates
function sphericalToSVG(theta, phi, svgSize) {
  // Zenith is center of plot

  // Ratio of phi
  const r = phi / (Math.PI / 2);

  const x = r * Math.cos(theta);
  const y = r * Math.sin(theta);

  return [svgSize * (x + 1), svgSize * (y + 1)];
}

function calculateJulianDate(year, month, day, hour = 0, minute = 0, second = 0) {
  if (month <= 2) {
      year -= 1;
      month += 12;
  }
  const A = Math.floor(year / 100);
  const B = 2 - A + Math.floor(A / 4);
  const JD = Math.floor(365.25 * (year + 4716)) + Math.floor(30.6001 * (month + 1)) + day + B - 1524.5;
  return JD + (hour + minute / 60 + second / 3600) / 24;
}

function calculateGMST(julianDate) {
  const JD0 = Math.floor(julianDate + 0.5) - 0.5;
  const H = (julianDate - JD0) * 24;
  const D = julianDate - 2451545.0;
  const D0 = JD0 - 2451545.0;
  const T = D / 36525.0;
  let GMST = 6.697374558 + 0.06570982441908 * D0 + 1.00273790935 * H + 0.000026 * (T ** 2);
  GMST = GMST % 24;
  if (GMST < 0) GMST += 24;
  return GMST;
}

function calculateLST(gmst, longitude) {
  let LST = gmst + longitude / 15.0;
  LST = LST % 24;
  if (LST < 0) LST += 24;
  return LST;
}

// Function to calculate the observer's longitude
function calculateObserverLongitude(lst, year, month, day, hour, minute, second) {
  const julianDate = calculateJulianDate(year, month, day, hour, minute, second);
  const gmst = calculateGMST(julianDate);
  const lstInHours = lst; // LST in hours
  let longitude = lstInHours - gmst;
  if (longitude < 0) {
      longitude += 24;
  }
  longitude = (longitude * 15) % 360; // Convert hours to degrees
  if (longitude > 180) {
      longitude -= 360;
  }
  return longitude;
}

export {
  radians,
  dotMtoV,
  geoToSpherical,
  sphericalToCartesian,
  cartesianToSpherical,
  getRotationMatrix,
  deltaSpherical,
  sphericalToSVG,
  calculateJulianDate,
  calculateGMST,
  calculateLST,
  calculateObserverLongitude
};