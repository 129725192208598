import Homepage from "./Static/Homepage";
import Create from "./Create/Create";
import Cart from "./Cart/Cart";
import Checkout from "./Checkout/Checkout";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom"
import SuccessPage from "./Static/Success";
import Contact from "./Static/Contact";
import TrackOrder from "./Static/trackorder/TrackOrder";
import { PrivacyPolicy, RefundPolicy, Terms } from "./Static/Policies";
import About from "./Static/About";

import { useEffect } from "react";

import ReactGA from 'react-ga4';
const TRACKING_ID = "G-TJJ9GHGS1L";


function App() {

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  return (
    <div className='App'>
      <Router>
        <Routes>
          <Route path='/' element={<Homepage />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/create' element={<Create />} />
          <Route path='/create/:edit' element={<Create />} />
          <Route path='/cart' element={<Cart />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/success' element={<SuccessPage />} />
          <Route path='/trackorder' element={<TrackOrder />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/refundpolicy' element={<RefundPolicy />} />
          <Route path='/terms' element={<Terms />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;