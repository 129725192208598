import React, { useEffect, useRef } from 'react';

import { secondsSinceJ2000 } from './DateHelpers';
import {radians, dotMtoV, sphericalToCartesian, cartesianToSpherical, rotateCoordinates, geoToSpherical, getRotationMatrix, deltaSpherical, sphericalToSVG} from './MathFunctions'

import starsJSON from './stars.6.json'
import constellationJSON from './constellations.json'
import milkywayJSON from './mw.json'

const readStars = () => {
  const starArray = starsJSON.features.map((feature) => {
    const mag = feature.properties.mag;
    const [longitude, latitude] = feature.geometry.coordinates;      

    return [longitude, latitude, mag];
  });

  return starArray
}

const readConstellations = () => {
  const constellationsArray = constellationJSON.features.map((feature) => {
    return feature.geometry.coordinates;
  });
  
  return constellationsArray
}

const readMilkyway = () => {
  const milkywayArray = milkywayJSON.features.map((feature) => {
    return feature.geometry.coordinates
  })

  return milkywayArray
}

export default function Starmap({options, padding=-1, shadow=true}){  
  const posterRef = useRef(null);

  
  const stars = readStars()
  const constellations = readConstellations()
  const milkyway = readMilkyway()
  
  useEffect(() => {
    if(options) {
      // set date incase it comes in as a string
      const epoch = new Date(options.epoch)

      // get obs laongitude
      const oneDay = 86400
      const oneRotation = 86164.09053 // this is how long it takes for earth to rotate around it's axis in seconds
      const deltaUTC = (-options.longitude / 360) * oneRotation
      const seconds = secondsSinceJ2000(epoch) + deltaUTC
      const timeRotation = (seconds % oneRotation) // seconds into a rotation
      const percentRotation = timeRotation /  oneRotation // percent in decimal of how far a rotation is
      const deltaLon = percentRotation * 360

      let [obsTheta, obsPhi] = geoToSpherical(options.longitude + deltaLon, options.latitude)
      let [deltaTheta, deltaPhi] = deltaSpherical(obsTheta, obsPhi)
      //rotation matrix
      var rotation = getRotationMatrix(deltaTheta, deltaPhi)

      const canvas = posterRef.current
      const ctx = canvas.getContext('2d')
      
      var height = canvas.height
      var width = canvas.width

      // clear canvas
      ctx.clearRect(0, 0, width, height);

      // background
      ctx.fillStyle = options.background;
      ctx.fillRect(0, 0, width, height);

      // border rectangle
      ctx.strokeStyle = 'white'
      ctx.lineWidth = width * 0.01
      ctx.strokeRect(height * 0.03, height * 0.03, width - (height * 0.06), height - (height * 0.06));


      // draw stars
      var radius = height * 0.6 / 2
      var start = (width - (radius * 2))/2
      var centerWidth = radius + (width * 0.1)
      var centerHeight = radius + (width * 0.1)

      // draw border
      ctx.beginPath();
      ctx.arc(centerWidth, centerHeight, radius, 0, 2 * Math.PI); // Outer circle
      ctx.strokeStyle = 'white';
      ctx.lineWidth = width * 0.005;
      ctx.stroke();
      ctx.closePath();

      ctx.save()
      ctx.clip()

      // draw stars
      stars.forEach(star => {
        const [lon, lat, mag] = star
        let [theta, phi] = geoToSpherical(lon, lat)
        let cartesian = sphericalToCartesian(theta, phi)
        let rotated_cartesian = dotMtoV(rotation, cartesian)
        let [newTheta, newPhi] = cartesianToSpherical(rotated_cartesian[0], rotated_cartesian[1], rotated_cartesian[2])

        if(newPhi < Math.PI/1.9) {
          let [x, y] = sphericalToSVG(newTheta + Math.PI, newPhi, radius)
          ctx.beginPath()
          ctx.arc(x + start, y + start, (7 - mag) * (radius/750), 0, 2 * Math.PI);
          ctx.fillStyle = 'white';
          ctx.fill();
          ctx.closePath();
        }
      })
      
      if(options.constellations) {
        // draw constellations
        constellations.forEach(constellation => {
          constellation.forEach(linesegment => {
            ctx.beginPath()
            linesegment.forEach((line, index) => {
              const [lon, lat] = line
              let [theta, phi] = geoToSpherical(lon, lat)
              let cartesian = sphericalToCartesian(theta, phi)
              let rotated_cartesian = dotMtoV(rotation, cartesian)
              let [newTheta, newPhi] = cartesianToSpherical(rotated_cartesian[0], rotated_cartesian[1], rotated_cartesian[2])
              
              if(newPhi < Math.PI/1.9) {
                let [x, y] = sphericalToSVG(newTheta + Math.PI, newPhi, radius)
                if(index === 0) {
                  ctx.moveTo(x + start, y + start);
                } else {
                  ctx.lineTo(x + start, y + start);
                }

              }
            })
            ctx.strokeStyle = 'white';
            ctx.lineWidth = 2 * (radius/750)
            ctx.stroke();
            ctx.closePath();
          })
        })
      }

      if(options.equatorial) {
        ctx.globalAlpha = 0.65; // opacity to 50%, reset after

        // latitude lines
        for(let phi = 0; phi < 180; phi += 10) {
          ctx.beginPath()
          let prevTheta = -5
          for(let theta = 0; theta < 361; theta += 5) {
            // transform them
            let cartesian = sphericalToCartesian(radians(theta), radians(phi))
            let rotated_cartesian = dotMtoV(rotation, cartesian)
            let [newTheta, newPhi] = cartesianToSpherical(rotated_cartesian[0], rotated_cartesian[1], rotated_cartesian[2])
            if(newPhi < Math.PI/1.9) {
              let [x, y] = sphericalToSVG(newTheta + Math.PI, newPhi, radius)
              if(theta === prevTheta + 5) {
                ctx.lineTo(x + start, y + start)
                prevTheta = theta
              } else {
                ctx.strokeStyle = 'white';
                ctx.lineWidth = 2 * (radius/750)
                ctx.stroke();
                ctx.closePath()
                ctx.beginPath()
                ctx.lineTo(x + start, y + start)
                prevTheta = theta
              }
            }
          }
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 2 * (radius/750)
          ctx.stroke();
          ctx.closePath()
        }

        // longitude lines
        for(let theta = 0; theta < 360; theta += 15){
          ctx.beginPath()
          let prevPhi = -5
          for(let phi = 0; phi < 181; phi += 5) {
            // transform them
            let cartesian = sphericalToCartesian(radians(theta), radians(phi))
            let rotated_cartesian = dotMtoV(rotation, cartesian)
            let [newTheta, newPhi] = cartesianToSpherical(rotated_cartesian[0], rotated_cartesian[1], rotated_cartesian[2])
            if(newPhi < Math.PI/1.9) {
              let [x, y] = sphericalToSVG(newTheta + Math.PI, newPhi, radius)
              if(phi === prevPhi + 5) {
                if(!(phi === 0 || phi === 5 || phi === 175 || phi === 180)) {
                  ctx.lineTo(x + start, y + start)
                }
                else if(theta % 90 === 0) {
                  ctx.lineTo(x + start, y + start)
                }
                prevPhi = phi
              } else {
                ctx.strokeStyle = 'white';
                ctx.lineWidth = 2 * (radius/750)
                ctx.stroke();
                ctx.closePath()
                ctx.beginPath()
                ctx.lineTo(x + start, y + start)
                prevPhi = phi
              }
            }
          }
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 2 * (radius/750)
          ctx.stroke();
          ctx.closePath()
        }

        ctx.globalAlpha = 1; // resetting opacity
      }

      // milkyway
      if(options.milkyway) {
        ctx.globalAlpha = 0.15
        
        milkyway.forEach(layer => {
          layer.forEach(polygon => {
            let firstCoord = true

            ctx.fillStyle = '#fff';
            ctx.beginPath();

            polygon.forEach(coordinate => {
              const [lon, lat] = coordinate
              let [theta, phi] = geoToSpherical(lon, lat)
              let cartesian = sphericalToCartesian(theta, phi)
              let rotated_cartesian = dotMtoV(rotation, cartesian)
              let [newTheta, newPhi] = cartesianToSpherical(rotated_cartesian[0], rotated_cartesian[1], rotated_cartesian[2])
              let [x, y] = sphericalToSVG(newTheta + Math.PI, newPhi, radius)

              if(newPhi < Math.PI/1.9) {
                if(firstCoord) {
                  ctx.moveTo(x + start, y + start)
                  firstCoord = false
                } else {
                  ctx.lineTo(x + start, y + start)
                }
              }
            })
            ctx.fill();
            ctx.closePath();
          })
        })
        
        ctx.globalAlpha = 1

      }

      ctx.restore()

      const text = [{'message': options.title, 'font': width * 0.06 + 'px Times New Roman', 'textY': height * 0.75},
                    {'message': options.subtitle, 'font': width * 0.03 + 'px Arial', 'textY': height * 0.785},
                    {'message': formatDate(options.epoch), 'font': width * 0.03 + 'px Courier New', 'textY': height * 0.86},
                    {'message': options.location, 'font': width * 0.03 + 'px Courier New', 'textY': height * 0.89},
                    {'message': formatLatitude(options.latitude) + ' ' + formatLongitude(options.longitude), 'font': width * 0.03 + 'px Courier New', 'textY': height * 0.92}]

      text.forEach(message => {
        ctx.font = message.font
        ctx.fillStyle = 'white'

        const textX = (width - ctx.measureText(message.message).width) / 2

        ctx.fillText(message.message, textX, message.textY)
      })
    }
  
  }, [options])
  return (
    <div className='flex justify-center items-center laptop:h-screen ' style={padding===-1 ? {paddingTop: window.innerWidth * 0.25 / 2, paddingBottom: window.innerWidth * 0.25 / 2} : {paddingTop: padding, paddingBottom: padding}}>
      <canvas className={`w-[75vw] h-[100vw] laptop:w-[60vh] laptop:h-[80vh] shadow-black ${shadow ? 'shadow-2xl' : ''}`} ref={posterRef} width={750} height={1000}></canvas>
    </div>
  )
};

export function StarmapPreview({color="black"}) {
  const previewRef = useRef(null);

  useEffect(() => {
    const canvas = previewRef.current

    if(canvas) {
      const ctx = canvas.getContext('2d')
    
      var height = canvas.height
      var width = canvas.width
    
      // clear canvas
      ctx.clearRect(0, 0, width, height);
    
      // background
      ctx.fillStyle = color;
      ctx.fillRect(0, 0, width, height);
    
      // border rectangle
      ctx.strokeStyle = 'white'
      ctx.lineWidth = width * 0.015
      ctx.strokeRect(height * 0.03, height * 0.03, width - (height * 0.06), height - (height * 0.06));
    
    
      // draw stars
      var radius = height * 0.6 / 2
      var centerWidth = radius + (width * 0.1)
      var centerHeight = radius + (width * 0.1)
    
      // draw border
      ctx.beginPath();
      ctx.arc(centerWidth, centerHeight, radius, 0, 2 * Math.PI); // Outer circle
      ctx.strokeStyle = 'white';
      ctx.lineWidth = width * 0.015;
      ctx.stroke();
      ctx.closePath();
    }
  })

  return (
    <canvas className='m-auto p-1 h-24' ref={previewRef} width={300} height={400} />
  )
}

function formatLatitude(lat) {
  if(lat >= 0) {
    return Math.abs(lat).toFixed(2) + '°N'
  } else {
    return Math.abs(lat).toFixed(2) + '°S'
  }
}
function formatLongitude(lon) {
  if(lon >= 0) {
    return Math.abs(lon).toFixed(2) + '°E'
  } else {
    return Math.abs(lon).toFixed(2) + '°W'
  }
}

function formatDate(dateObj) {
  dateObj = new Date(dateObj)
  const month = getMonthName(dateObj.getMonth());
  const year = dateObj.getFullYear();
  const day = dateObj.getDate();
  const daySuffix = getDaySuffix(day);

  const hour = dateObj.getHours()
  const minute = dateObj.getMinutes()

  return `${month} ${day}${daySuffix}, ${year} ${formatTime(hour, minute)}`;
}

function getMonthName(monthIndex) {
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  return months[monthIndex];
}

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
      return 'th';
  }
  switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
  }
}

function formatTime(hour, minutes) {
  // Validate inputs
  if (hour < 0 || hour > 23 || minutes < 0 || minutes > 59) {
    throw new Error("Invalid input: Hour must be between 0-23 and minutes must be between 0-59.");
  }

  // Determine AM/PM
  const period = hour >= 12 ? "PM" : "AM";

  // Convert hour to 12-hour format
  let formattedHour = hour % 12;
  formattedHour = formattedHour ? formattedHour : 12; // The hour '0' should be '12'

  // Pad minutes with leading zero if necessary
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

  // Return formatted time
  return `${formattedHour}:${formattedMinutes} ${period}`;
}