// CheckoutForm.js

import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js';

import axios from 'axios'

import shippingSVG from './shipping.svg'

const CheckoutForm = ({email, setEmail, id}) => {
  const [shippingString, setShippingString] = useState('Enter Shipping Info')

  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        receipt_email: email,
        return_url: 'http://cosmokeepsakes.com/success',
      },
    })

    if (result.error) {
      console.error(result.error.message);
    }
  };
  const handleAddressChange = async (event) => {
    if (event.complete) {
      let address = event.value
      setShippingString('Estimating Time...')
      try {
        const response = await axios.post('https://server-e4ys6pw2bq-uc.a.run.app/shipping-time', { address, id: id });
        setShippingString(`${formatDate(response.data.min)} to ${formatDate(response.data.max)}`)
      } catch (error) {
        setShippingString('Error fetching shipping time:', error);
      }
    } else {
      setShippingString('Enter Shipping Info');
    }
  };

  return (
    <form className='flex flex-col justify-between w-full laptop:h-screen laptop:max-h-screen mx-auto' onSubmit={handleSubmit}>
      <div className='w-11/12 max-w-[500px] mx-auto p-1 flex-grow overflow-y-auto'>
        <p className='pt-6 pb-0 text-2xl font-semibold text-lightblack'>Checkout</p>
        <p className='pt-0 text-gray underline'><a href='/cart'>Back</a></p>
        <h2 className="pt-6 pb-1 text-lg font-medium text-lightblack">Contact Information</h2>
        <p className=' text-lightblack'>Email</p>
        <input className='w-full px-3 py-2 text-lightblack border-[1px] border-stripelightgray placeholder-stripegray focus:ring-[3px] focus:ring-stripelightblue focus:border-stripeblue outline-none rounded-md shadow-sm transition duration-200 ease-in-out required:outline-4' type='email' value={email} onChange={(e) => {setEmail(e.target.value)}} placeholder='youremail@gmail.com' />
        <h2 className="pt-6 pb-1 text-lg font-medium text-lightblack">Shipping Information</h2>
        <AddressElement options={{mode: 'shipping'}} onChange={handleAddressChange} />
        <h2 className="pt-6 pb-1 text-lg font-medium text-lightblack">Payment Information</h2>
        <PaymentElement />
      </div>

      <div className=''>
        <hr className=' border-lightgray' />
        <div className='w-11/12 m-auto py-5'>
          <div className='flex flex-col justify-between w-full'>
            <div className={`flex flex-row  ${ shippingString === 'Estimating Time...' ? 'animate-pulse duration-1000' : ''}`}>
              {/* shipping */}
              <img className='h-12 w-12' src={shippingSVG} />
              <p className='my-auto ml-2 text-text font-semibold text-lg'>{shippingString}</p>
            </div>
            <p className='my-auto text-gray text-sm'>Double check your order!</p>
          </div>
          <button className=' w-full my-2 py-4 bg-secondary text-white text-lg font-medium rounded-lg' disabled={!stripe}>Buy Now</button>
          <div className="flex justify-between text-base font-small text-black">
            <a className=' text-gray text-sm underline' href='terms'>Terms of Service</a>
            <a className=' text-gray text-sm underline' href='returnpolicy'>Return Policy</a>
            <a className=' text-gray text-sm underline' href='privacypolicy'>Privacy Policy</a>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;


function formatDate(dateStr) {
  const months = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const date = new Date(dateStr);
  const month = months[date.getMonth()];
  const day = date.getDate();
  const daySuffix = (day) => {
    if (day > 3 && day < 21) return 'th';
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };

  return `${month} ${day}${daySuffix(day)}`;
}