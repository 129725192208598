import { Navbar, Footer } from "./StaticComponents"

import aboutImage from "./about.jpg"
import grizzly from "./grizzly.JPG"

import ReactGA from "react-ga4";

export default function About() {
  ReactGA.send({ hitType: "pageview", page: "/about", title: "About" });

  return (
    <div className=''>
      <Navbar background={'#9D90BE'} />

      <div className="flex flex-col w-11/12 max-w-[1280px] mx-auto py-32">
        <div className="flex flex-col laptop:flex-row w-full">
          <div className="flex flex-col w-full laptop:w-7/12 p-5 bg-whitegray rounded-lg shadow-lg">
            <h1 className="text-4xl font-bold text-text">About Us</h1>
            
            <p className="text-lg text-text mt-2">
              We are a small business with a passion surrounding space and everything about it. 
              To us, the stars mean so much more than spots of light in the sky. They are storytellers, 
              chronicling the most important moments in our lives. From the day you met the love of your life 
              to the birth of a child, every significant event has a corresponding night sky. 
              Our mission is to capture these celestial moments and preserve them forever.
            </p>

            <p className="text-lg text-text mt-4">
              Imagine being able to look back at the night sky on the day of your wedding, or the moment you 
              first held your baby. With our custom star maps, you can hold on to these precious memories 
              and keep them close to your heart. Each star map is meticulously crafted to reflect the exact 
              alignment of the stars on your special night, creating a unique and personalized piece of art 
              that tells your story.
            </p>

            <p className="text-lg text-text mt-4">
              Let us help you freeze time and capture the magic of the stars. Whether it's for yourself or 
              as a heartfelt gift for someone special, our star maps are a timeless way to commemorate the 
              moments that matter most.
            </p>
          </div>
          <div className="flex w-full laptop:w-5/12 h-auto laptop:justify-end laptop:ml-5 mt-4 laptop:mt-0">
            <img className="overflow-clip rounded-lg" src={aboutImage} />
          </div>
        </div>

        <div className="flex flex-col laptop:flex-row w-full mt-20">
          <div className="laptop:order-2 flex flex-col w-full laptop:w-7/12 p-5 bg-whitegray rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-text mb-4">Our Mission</h2>
            <p className="text-text leading-relaxed">
              Our mission is to bring the beauty of the night sky into your home. We create personalized star maps 
              that capture the stars' alignment on your special date and location. Whether it's a birthday, anniversary, 
              or any significant moment, we help you preserve it forever.
            </p>

            <h2 className="text-2xl font-semibold text-text mb-4 pt-5">Our Values</h2>
            <ul className="list-disc list-inside text-text leading-relaxed">
              <li>Creativity: We believe in the power of art to tell stories and capture memories.</li>
              <li>Quality: Our star maps are printed on high-quality materials to ensure they last a lifetime.</li>
              <li>Customer Satisfaction: We strive to exceed our customers' expectations with every order.</li>
              <li>Passion: We are passionate about astronomy and sharing the wonders of the night sky with everyone.</li>
              <li>Integrity: We conduct our business with honesty and transparency.</li>
            </ul>
          </div>
          <div className="laptop:order-1 flex w-full laptop:w-5/12 h-auto laptop:justify-end laptop:mr-5 mt-4 laptop:mt-0">
            <img className="overflow-clip rounded-lg" src={grizzly} />
          </div>
        </div>

      </div>

      <Footer />
    </div>

  )
}