import Starmap from "./Starmap"
import { SatelliteIcon, UrsaMinorIcon, PersonalizeIcon } from "./IndexIcons"
import { ConstellationIcon, MilkywayIcon, EquatorialIcon } from "./DesignIcons"
import { useState, useEffect, useRef } from 'react'
import { useLocation, useParams } from "react-router-dom"

import { dateToDateObj, dateObjToDate } from "./DateHelpers"

import ReactGA from "react-ga4";

export default function Create() {
  ReactGA.send({ hitType: "pageview", page: "/create", title: "Create" });

  const { edit } = useParams();

  const addToCart = () => { 
    const existingMap = localStorage.getItem('map');

    if (existingMap) {
      const mapArray = JSON.parse(existingMap);
      if(edit) { // if edit
        mapArray[edit] = options
        localStorage.setItem('map', JSON.stringify(mapArray));
      } else { // else add to end
        mapArray.push(options);
        localStorage.setItem('map', JSON.stringify(mapArray));
      }
    } else { // create local storage if first create or not there
      localStorage.setItem('map', JSON.stringify([options]));
    }
    window.location.href = '/cart'
  }

  // design options
  const [options, setOptions] = useState({
    'location': '',
    'latitude': 0,
    'longitude': 0,
    'epoch': dateObjToDate(new Date(Date.now())),
    'background': '#121b3a',
    'milkyway': false,
    'constellations': true,
    'equatorial': true,
    'title': '',
    'subtitle': '',
    'size': '24x32',
    'quantity': 1,
  })

  // if its an edit from cart
  useEffect(() => {
    if(edit) {
      setOptions(JSON.parse(localStorage.getItem('map'))[edit])
    }
  }, [edit])

  return (
    <div className="flex flex-col laptop:flex-row laptop:h-screen bg-lightgray">
      <div className="sticky top-0 laptop:w-3/4">
        <Starmap options={options} />
      </div>
      <div className="w-11/12 z-10 mx-auto laptop:w-1/4 rounded-lg bg-white shadow-lg pt-10 overflow-y-auto">
        <Spacetime options={options} setOptions={setOptions} />
        <Personalize options={options} setOptions={setOptions}  />
        <Preview options={options} setOptions={setOptions} next={addToCart} />
      </div>
    </div>
  )
}

function Spacetime({options, setOptions}) {

  const autoCompleteRef = useRef()
  const inputRef = useRef()
  const autoCompleteOptions = {
    fields: ['address_components', 'geometry', 'icon', 'name'],
  }
  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      autoCompleteOptions
    )
    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace()
      setOptions((prevOptions) => ({
        ...prevOptions,
        location: place.name,
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      }));
    })
  }, [])

  return (
    <div className=" flex flex-col w-2/3 m-auto pt-4">
      <p className="font-medium text-2xl">Navigate</p>
      <div className="pt-2 py-2">
        <p className=" text-gray text-md">Location:</p>
        <input ref={inputRef} className="bg-lightgray border-none w-full py-2 px-4 rounded-lg shadow-md" type="text" id="latitude" value={options.location} onChange={(e) => setOptions({...options, location: e.target.value})} />
      </div>
      <div className="py-2">
        <p className=" text-gray text-md">Time:</p>
        <input className=" bg-lightgray border-none w-full py-2 px-4 rounded-lg shadow-md" type="datetime-local" value={options.epoch} onChange={(e) => setOptions({ ...options, epoch: e.target.value })} />
      </div>
    </div>
  )
}

function Personalize({options, setOptions}) {
  return (
    <div className="flex flex-col w-2/3 m-auto pt-4">
      <p className="font-medium text-2xl">Personalize</p>
      <div className="pt-2 py-2">
        <p className=" text-gray text-md">Color:</p>
        <BackgroundOptions background={options.background} setBackground={(e) => setOptions({ ...options, background: e})} />
      </div>

      <div className=" py-2">
        <p className=" text-gray text-md">Design Options:</p>
        <div className="mt-1 flex flex-row justify-start">
          {/* <button className="w-[23%] p-1 rounded-lg border border-lightgray cursor-pointer" onClick={() => setOptions({ ...options, milkyway: !options.milkyway})} style={options.milkyway ? {borderColor: '#999', borderWidth: '2px'} : {}}>
            <MilkywayIcon active={options.milkyway} />
          </button> */}
          <button className="w-[23%] p-1 mr-[5vw] laptop:mr-[1.5vw] rounded-lg border border-lightgray cursor-pointer" onClick={() => setOptions({ ...options, equatorial: !options.equatorial})} style={options.equatorial ? {borderColor: '#999', borderWidth: '2px'} : {}} >
            <EquatorialIcon active={options.equatorial} />
          </button>
          <button className="w-[23%] p-1 rounded-lg border border-lightgray cursor-pointer" onClick={() => setOptions({ ...options, constellations: !options.constellations})} style={options.constellations ? {borderColor: '#999', borderWidth: '2px'} : {}} >
            <ConstellationIcon active={options.constellations} />
          </button>
        </div>
      </div>

      <div className="pt-6 py-2">
        <p className=" text-gray text-md">Title:</p>
        <input className="bg-lightgray border-none w-full py-2 px-4 rounded-lg shadow-md" type="text" placeholder="Something Special :)" value={options.title} onChange={(e) => setOptions({ ...options, title: e.target.value})} />
      </div>
      <div className="py-2">
        <p className=" text-gray text-md">Subtitle:</p>
        <textarea className="bg-lightgray border-none w-full py-2 px-4 rounded-lg shadow-md resize-none" type="text" placeholder="And what else?" value={options.subtitle} onChange={(e) => setOptions({ ...options, subtitle: e.target.value})} />
      </div>
    </div>
  )
}

function BackgroundOptions({background, setBackground}) {
  return (
    <fieldset className="flex flex-wrap mt-1 gap-3">
      <legend className="sr-only">Color</legend>

      <label
        htmlFor="ColorDarkBlue"
        className="block size-8 cursor-pointer rounded-full bg-[#121b3a] shadow-sm has-[:checked]:ring-2 has-[:checked]:ring-[#121b3a] has-[:checked]:ring-offset-2"
      >
        <input type="radio" name="ColorOption" value="#121b3a" id="ColorDarkBlue" className="sr-only" checked={background === "#121b3a"} onChange={(e) => setBackground(e.target.value)} />

        <span className="sr-only">Dark Blue</span>
      </label>

      <label
        htmlFor="ColorBlack"
        className="block size-8 cursor-pointer rounded-full bg-black shadow-sm has-[:checked]:ring-2 has-[:checked]:ring-black has-[:checked]:ring-offset-2"
      >
        <input type="radio" name="ColorOption" value="#000" id="ColorBlack" className="sr-only" checked={background === "#000"} onChange={(e) => setBackground(e.target.value)} />

        <span className="sr-only">Black</span>
      </label>

      <label
        htmlFor="ColorGalaxyBlue"
        className="block size-8 cursor-pointer rounded-full bg-galaxyBlue shadow-sm has-[:checked]:ring-2 has-[:checked]:ring-galaxyBlue has-[:checked]:ring-offset-2"
      >
        <input type="radio" name="ColorOption" value="#000044" id="ColorGalaxyBlue" className="sr-only" checked={background === "#000044"} onChange={(e) => setBackground(e.target.value)} />

        <span className="sr-only">Galaxy Blue</span>
      </label>

    </fieldset>
  )
}

function Preview({ options, setOptions, next }) {
  const { edit } = useParams()

  const isActive = (size) => { return options.size===size}

  return (
    <div className="flex flex-col w-2/3 m-auto pt-4">
      <p className="font-medium text-2xl">Preview</p>
      <div className=" pt-2 py-2">
        <p className=" text-gray text-md">Size:</p>
        <div className="flex flex-row laptop:flex-wrap desktop:flex-nowrap justify-between">
          <div className="w-[23%] laptop:w-{} p-1 rounded-lg border border-lightgray cursor-pointer" style={ isActive("12x16") ? {borderColor: '#999', borderWidth: '2px'} : {}} onClick={() => setOptions({ ...options, size: '12x16'})}>
            <p className=" text-center text-gray text-sm laptop:text-md">12x16"</p>
            <svg className="w-full m-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" version="1.0">
              <rect className="stroke-lightgray stroke-2" style={ isActive("12x16") ? {stroke: '#5f43b2'} : {}} fill="none" width="12" height="16" x="19" y="17" />
            </svg>
            <p className=" text-center text-gray text-sm laptop:text-md">$49.99</p>
          </div>
          <div className="w-[23%] p-1 rounded-lg border border-lightgray cursor-pointer" style={ isActive("18x24") ? {borderColor: '#999', borderWidth: '2px'} : {}} onClick={() => setOptions({ ...options, size: '18x24'})}>
            <p className=" text-center text-gray text-sm laptop:text-md">18x24"</p>
            <svg className="w-full m-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" version="1.0">
              <rect className="stroke-lightgray stroke-2" style={ isActive("18x24") ? {stroke: '#5f43b2'} : {}} fill="none" width="18" height="24" x="16" y="13" />
            </svg>
            <p className=" text-center text-gray text-sm laptop:text-md">$59.99</p>
          </div>
          <div className="w-[23%] p-1 rounded-lg border border-lightgray cursor-pointer" style={ isActive("24x32") ? {borderColor: '#999', borderWidth: '2px'} : {}} onClick={() => setOptions({ ...options, size: '24x32'})}>
            <p className=" text-center text-gray text-sm laptop:text-md">24x32"</p>
            <svg className="w-full m-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" version="1.0">
              <rect className="stroke-lightgray stroke-2" style={ isActive("24x32") ? {stroke: '#5f43b2'} : {}} fill="none" width="24" height="32" x="13" y="9" />
            </svg>
            <p className=" text-center text-gray text-sm laptop:text-md">$74.99</p>
          </div>
          <div className="w-[23%] p-1 rounded-lg border border-lightgray cursor-pointer" style={ isActive("30x40") ? {borderColor: '#999', borderWidth: '2px'} : {}} onClick={() => setOptions({ ...options, size: '30x40'})}>
            <p className=" text-center text-gray text-sm laptop:text-md">30x40"</p>
            <svg className="w-full m-auto cursor-pointer" xmlns="http://www.w3.org/2000/svg" zoomAndPan="magnify" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" version="1.0">
              <rect className="stroke-lightgray stroke-2" style={ isActive("30x40") ? {stroke: '#5f43b2'} : {}} fill="none" width="30" height="40" x="10" y="5" />
            </svg>
            <p className=" text-center text-gray text-sm laptop:text-md">$79.99</p>
          </div>
        </div>
      </div>
      
      <div className="flex justify-between pt-6 pb-10">
        <button className="invisible w-5/12">t</button>
        <button className="w-5/12 py-2 rounded-lg bg-secondary text-white font-semibold" onClick={next}>{ edit ? 'Edit' : 'Add To Cart'}</button>
      </div>
    </div>
  )
}