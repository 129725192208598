import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Starmap from '../Create/Starmap'
import { StarmapPreview } from '../Create/Starmap'

import ReactGA from "react-ga4";

const prices = {
  "12x16": 4999,
  "18x24": 5999,
  "24x32": 7499,
  "30x40": 7999,
}

export default function Cart () {
  ReactGA.send({ hitType: "pageview", page: "/cart", title: "Cart" });

  const [activeID, setActiveID] = useState(0)
  const [isCartEmpty, setIsCartEmpty] = useState(true)
  // used only to see if the quantity has changed to update subtotal
  const [quantityChange, setQuantityChange] = useState(0)

  const [products, setProducts] = useState([])
  const removeProduct = (id) => {
    const updatedProducts = [...products]
    updatedProducts.splice(id, 1)

    setProducts(updatedProducts)

    localStorage.setItem('map', JSON.stringify(updatedProducts))

    if(updatedProducts.length === 0) {
      setIsCartEmpty(true)
    }

    setQuantityChange(quantityChange - 1)
  }

  useEffect(() => {
    if(localStorage.getItem('map').length === 0) {
      setIsCartEmpty(true)
    } else {
      setIsCartEmpty(false)
    }
  }, [activeID])

  useEffect(() => {
    const optionsList = localStorage.getItem('map')

    if (optionsList) {
      setProducts(JSON.parse(optionsList))
    }
  } , [])

  return ( 
    <div className="flex flex-col laptop:flex-row laptop:h-screen bg-lightgray">
      <div className="sticky m-auto top-0 w-3/4">
        {
          !isCartEmpty ? <Starmap options={JSON.parse(localStorage.getItem('map'))[activeID]} /> : <></>
        }
      </div>
      <div className="flex flex-col w-11/12 z-10 mx-auto laptop:w-1/4 rounded-lg bg-white shadow-lg">
        <div className='flex-grow overflow-y-auto'>
          <ProductList products={products} activeID={activeID} setActiveID={setActiveID} removeProduct={removeProduct} quantityChange={quantityChange} setQuantityChange={setQuantityChange} />
        </div>
        <div className=''>
          <Subtotal quantityChange={quantityChange} />
        </div>
      </div>
    </div>
  )
}

function Subtotal ({quantityChange}) {
  const [subtotal, setSubtotal] = useState(0)
  useEffect(() => {
    const caclulateSubtotal = () => {
      let products = JSON.parse(localStorage.getItem('map'))
  
      let sum = 0
      for(let i = 0; i < products.length; i++) {
        sum += prices[products[i]['size']] * products[i]['quantity']
      }
      return sum
    }
    console.log('quantity changed')
    setSubtotal(caclulateSubtotal())

  }, [quantityChange])


  return (
    <div>
      <hr className=' border-lightgray' />
      <div className=' w-11/12 m-auto py-5'>
        <div className="flex justify-between text-base font-medium text-black">
          <h3>Subtotal</h3>
          <p className="ml-4">${subtotal/100}</p>
        </div>
        <p className=' text-gray text-sm'>Shipping and taxes are calculated at checkout</p>
        <button className=' w-full mt-4 mb-2 py-4 bg-secondary text-white text-lg font-medium rounded-lg' onClick={() => {window.location.href = '/checkout'}}>Checkout</button>
        <div className="flex justify-between text-base font-small text-black">
          <a className=' text-gray text-sm underline' href='terms'>Terms of Service</a>
          <a className=' text-gray text-sm underline' href='returnpolicy'>Return Policy</a>
          <a className=' text-gray text-sm underline' href='privacypolicy'>Privacy Policy</a>
        </div>
      </div>

    </div>
  )
}

function ProductList ( {products, removeProduct, activeID, setActiveID, quantityChange, setQuantityChange}) {

  const getProducts = () => {
    if (products.length > 0) {  
      const elements = [];
  
      products.forEach((element, index) => {
        elements.push(
          <Product size={element.size} id={index} activeID={activeID} setActiveID={setActiveID} key={index} removeProduct={removeProduct} quantityChange={quantityChange} setQuantityChange={setQuantityChange} />
        );
      });
  
      // Return the array of elements
      return elements;
    }
    else { console.log('there are no products')}
  }

  return (
    <div className='w-11/12 mx-auto max-w-[500px]'>
      <h2 className="pt-6 text-lg font-medium text-black">Shopping Cart</h2>
      <ul role="list" className="mx-auto divide-y divide-lightgray">
        {getProducts()}
      </ul>
      {products.length > 0 ?
        <button className='flex mx-auto my-4 p-2 border-2 rounded-md bg-white text-secondary font-medium' onClick={() => window.location.href = 'create'}>Create Another</button>
        :
        <div className='mt-4'>
          <h1 className='text-center text-gray'>Looks like you have no items!</h1>
          <button className='flex mx-auto mt-1 p-2 border-2 rounded-md bg-white text-secondary font-medium' onClick={() => window.location.href = 'create'}>Create A Design</button>
        </div>
      }

    </div>

  )
}

function Product ({ size, id, activeID, setActiveID, removeProduct, quantityChange, setQuantityChange }) { 
  const navigate = useNavigate()
  const toEdit = () => {
    navigate(`/create/${id}`);
  };
  
  const [localQuantity, setLocalQuantity] = useState(JSON.parse(localStorage.getItem('map'))[id]['quantity'])
  const setQuantity = (id, quantity) => {
    let productsJSON = JSON.parse(localStorage.getItem('map'))
    productsJSON[id]['quantity'] = quantity

    localStorage.setItem('map', JSON.stringify(productsJSON))
    setLocalQuantity(quantity)
    setQuantityChange(quantityChange += 1)
  }

  return (
    <li className="flex py-6">
      <div className="flex-shrink-0 h-24 w-24 cursor-pointer overflow-hidden rounded-md border border-lightgray" onClick={() => setActiveID(id)}>
        <StarmapPreview color={JSON.parse(localStorage.getItem('map'))[id]['background']} />
        {/* <img src={poster} alt="Salmon orange fabric pouch with match zipper, gray zipper pull, and adjustable hip belt." className="p-1 h-full m-auto" /> */}
      </div>

      <div className="ml-4 flex flex-1 flex-col">
        <div>
          <div className="flex justify-between text-base font-medium text-black">
            <h3  style={id === activeID ? {textDecoration: 'underline'} : {}}>Starmap Poster</h3>
            <p className="ml-4">${(prices[size] / 100) * localQuantity}</p>
          </div>
          <div className="flex justify-between text-base text-black">
            <p className="mt-1 text-sm text-gray">{size}"</p>
            <select value={localQuantity} onChange={(e) => setQuantity(id, e.target.value)}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
              <option value={4}>4</option>
              <option value={5}>5</option>
            </select>
          </div>
        </div>
        <div className="flex flex-1 items-end justify-between text-sm">
          <p className="text-gray cursor-pointer hover:underline" onClick={toEdit}>Edit</p>

          <div className="flex">
            <button type="button" className="font-medium text-primary hover:text-secondary" onClick={() => removeProduct(id)}>Remove</button>
          </div>
        </div>
      </div>
    </li>
  )
}