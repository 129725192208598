import logo from './moon.svg'
import wall from './wall.jpg'
import wallart from './heroposter.png'
import couchmockup from './couchmockup.png'
import couch from './3frames.png'
import paper from './matte.png'
import Features from './Features'

import personalize from './icons/personalize.svg'
import packageSVG from './icons/package.svg'
import trophy from './icons/trophy.svg'
import freeshipping from './icons/freeshipping.svg'
import cart from './icons/cart.svg'

import woman from './images/woman.jpg'
import woman_avatar from './images/woman_avatar.png'
import stars5 from './images/5star.svg'

import { Navbar, Footer, BetterFooter, ProductCard } from './StaticComponents'

import { Accordion, AccordionPanel, AccordionHeading, AccordionSegment } from './Accordion'

import ReactGA from "react-ga4";
import { ContactUs } from './Contact'
import { useEffect } from 'react'

export default function Homepage () {
  ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });

  return (
    <div>
      <Navbar background={'#9D90BE'} />

      {/* Hero */}
      <div className='w-full bg-cover bg-bottom' style={{ height: window.innerHeight - 150 ,backgroundImage: `url(${wall})` }}>
        <div className='flex flex-col tablet:flex-row w-11/12 max-w-[1280px] h-screen mx-auto'>
          <div className='mx-auto mt-20 tablet:order-2' style={{ width: '50vw', height: 'calc(50vw * (800 / 643))', maxWidth: '30vh', maxHeight: 'calc(30vh * (800 / 643))' }}>
            <img className='w-full h-full object-contain' src={wallart} alt="Wall Art" />
          </div>
          <div className='flex flex-col w-11/12 max-w-[1280px] mx-auto pt-5 tablet:pt-0 tablet:justify-center tablet:h-full text-white tablet:order-1'>
            <h1 className='text-2xl laptop:text-4xl font-bold font-serif text-center tablet:text-left'>Capture the Stars, Cherish the Moment</h1>
            <h1 className='hidden laptop:block text-xl text-center tablet:w-3/4 tablet:text-left'>Transform your special memories into stunning, high-quality star maps. Celebrate life's precious moments with a personalized gift that's as unique as your story.</h1>
            <div className="flex flex-col items-center tablet:items-start mt-3">
              <button onClick={() => window.location.href = '/create'} className="w-auto bg-white text-lightblack font-bold rounded-full laptop:mt-0 py-4 px-8">
                Create Now
              </button>
            </div>     
          </div>
        </div>
      </div>

      <div className=' bg-whitegray'>
        <div className="flex flex-col laptop:flex-row items-center w-11/12 max-w-[1280px] mx-auto py-16">
          <div className="flex flex-col justify-center text-center w-full laptop:w-1/2 laptop:p-16 text-lightblack">
            <h1 className="text-3xl laptop:text-4xl font-bold">The Greatest Gifts come from the Heart</h1>
            <p className="mt-[-1] text-xl laptop:mt-4">Show your loved ones how much they mean to you with personalized star maps that capture the magic of your most cherished moments.</p>
          </div>
          <div className="w-full laptop:w-1/2 mt-8 laptop:mt-0 laptop:p-16">
            <ProductCard />
          </div>
        </div>
      </div>


      {/* Elaborate on why it's the perfect gift */}
      <div className="w-11/12 max-w-[1280px] mx-auto py-32 text-center">
        <h2 className="text-3xl text-lightblack font-bold mb-6">Our Commitment</h2>
        <div className="flex flex-col tablet:flex-row tablet:flex-wrap justify-center">
          <div className="tablet:w-1/3 p-4">
            <img src={trophy} className="mx-auto w-20 h-20 mb-4" alt="High Quality" />
            <h3 className="text-xl font-semibold">High Quality</h3>
            <p className="text-stripegray">Made with musuem grade materials to last years.</p>
          </div>
          <div className="tablet:w-1/3 p-4">
            <img src={packageSVG} className="mx-auto w-20 h-20 mb-4" alt="Beautiful Packaging" />
            <h3 className="text-xl font-semibold">Robust Packaging</h3>
            <p className="text-stripegray">Comes in paper dedicated packaging to prevent wrinkles.</p>
          </div>
          <div className="tablet:w-1/3 p-4">
            <img src={freeshipping} className="mx-auto w-20 h-20 mb-4" alt="Personalization" />
            <h3 className="text-xl font-semibold">Free Shipping</h3>
            <p className="text-stripegray">We offer free shipping to anywhere in the world.</p>
          </div>
        </div>
      </div>

      <div className=' overflow-hidden'>
        <div className='w-[150vw] h-[105vw] tablet:w-full tablet:h-[42vw]'>
          <img className=' ml-[-25vw] mt-[-18vw] tablet:ml-0 tablet:mt-[-10vw]' src={couch} />
        </div>
      </div>

      <div className="flex flex-col tablet:flex-row items-center w-11/12 max-w-[1280px] mx-auto my-16">
        <div className="w-full tablet:w-1/2 p-8 tablet:p-0 laptop:p-16">
          <img src={paper} alt="Our Paper" className="w-full h-auto" />
        </div>
        <div className="w-full tablet:w-1/2 p-8 tablet:p-16 text-lightblack">
          <h1 className="text-3xl tablet:text-4xl font-bold mb-8">Features</h1>
          <div className="space-y-8">
            <div className="flex items-start">
              <div className="mr-4">
                <svg className="w-8 h-8 fill-current text-lightblack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12 0C5.383 0 0 5.383 0 12c0 6.618 5.383 12 12 12s12-5.383 12-12C24 5.383 18.618 0 12 0zm0 21.75c-5.374 0-9.75-4.377-9.75-9.75S6.627 2.25 12 2.25s9.75 4.377 9.75 9.75-4.376 9.75-9.75 9.75z"/>
                  <path d="M16.667 7.333L9.333 12 7.333 10.333 6 11.667 9.333 15l8-6.667z"/>
                </svg>
              </div>
              <div>
                <h2 className="text-xl font-semibold">Museum Grade</h2>
                <p className="mt-1">Our paper is made from premium materials, ensuring the best quality.</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="mr-4">
                <svg className="w-8 h-8 fill-current text-lightblack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12 0C5.383 0 0 5.383 0 12c0 6.618 5.383 12 12 12s12-5.383 12-12C24 5.383 18.618 0 12 0zm0 21.75c-5.374 0-9.75-4.377-9.75-9.75S6.627 2.25 12 2.25s9.75 4.377 9.75 9.75-4.376 9.75-9.75 9.75z"/>
                  <path d="M16.667 7.333L9.333 12 7.333 10.333 6 11.667 9.333 15l8-6.667z"/>
                </svg>
              </div>
              <div>
                <h2 className="text-xl font-semibold">FCS Certified</h2>
                <p className="mt-1">Our paper is sourced from sustainable forests and is 100% recyclable.</p>
              </div>
            </div>
            <div className="flex items-start">
              <div className="mr-4">
                <svg className="w-8 h-8 fill-current text-lightblack" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                  <path d="M12 0C5.383 0 0 5.383 0 12c0 6.618 5.383 12 12 12s12-5.383 12-12C24 5.383 18.618 0 12 0zm0 21.75c-5.374 0-9.75-4.377-9.75-9.75S6.627 2.25 12 2.25s9.75 4.377 9.75 9.75-4.376 9.75-9.75 9.75z"/>
                  <path d="M16.667 7.333L9.333 12 7.333 10.333 6 11.667 9.333 15l8-6.667z"/>
                </svg>
              </div>
              <div>
                <h2 className="text-xl font-semibold">Alkaline Technology</h2>
                <p className="mt-1">We offer the best prices for the highest quality paper products.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* Testimonies */}
      <div className='relative w-full my-24 laptop:my-0 py-12 laptop:py-28 bg-wallpurp laptop:bg-opacity-0'>
        <div className='hidden laptop:block laptop:absolute w-full h-[550px] mt-[25px] bg-wallpurp z-0' />
        <div className='relative flex flex-col laptop:flex-row w-11/12 max-w-[1280px] mx-auto z-10'>
          <img className='hidden laptop:block h-[600px] rounded-2xl' src={woman} />
          <div className='laptop:ml-8 my-auto'>
            <p className='text-xl laptop:text-2xl font-semibold text-whitegray'>"Cosmic Keepsakes is my go-to gift for all special occasions. Whether it's for births, weddings, birthdays, or just to surprise a friend, these star maps are perfect. They are unique, personal, and always leave a lasting impression."</p>
            <div className='hidden laptop:flex flex-row mt-8'>
              <p className=' text-white text-xl font-semibold'>Jessica</p>
              <img className='ml-4 w-28' src={stars5} alt='5 stars' />
            </div>
            <p className='hidden laptop:block mt-[-10px] text-stripelightgray text-lg'>Customer</p>

            <img className='laptop:hidden mb-1 mt-6 w-36' src={stars5} alt='5 stars' />
            <div className='laptop:hidden flex flex-row'>
              <img className='w-12 rounded-full' src={woman_avatar} alt='5 stars' />
              <div className='flex flex-col ml-4'>
                <p className=' text-white text-xl font-semibold'>Jessica</p>
                <p className='mt-[-10px] text-stripelightgray text-lg'>Customer</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Picture */}
      {/* FAQ */}
      <div className='w-11/12 max-w-[1280px] mx-auto pb-10 text-text'>   
        <h2 className='pb-5 text-2xl font-semibold'>FAQ</h2>   
        <Accordion>
          <AccordionSegment>
            <AccordionHeading><p className='text-lg'>How long does shipping take?</p></AccordionHeading>
            <AccordionPanel>
              <p className='pb-5'>For most of the world shipping usually takes 4 to 7 days so plan on this if you are buying to gift. You can find a live estimate at checkout.</p>
            </AccordionPanel>
          </AccordionSegment>
          <AccordionSegment>
            <AccordionHeading><p className='text-lg'>How much is shipping?</p></AccordionHeading>
            <AccordionPanel>
              <p className='pb-5'>We offer free shipping to everyone!</p>
            </AccordionPanel>
          </AccordionSegment>
          <AccordionSegment>
            <AccordionHeading><p className='text-lg'>How accurate is the star map?</p></AccordionHeading>
            <AccordionPanel><p className=' pb-5'>Our star maps are accurate down to ±0.0005 of a second due to our custom algorithm. Don't believe it? Try testing our map at night yourself! (Tip: Find the little dipper or summer triangle)</p></AccordionPanel>
          </AccordionSegment>
          <AccordionSegment>
            <AccordionHeading><p className='text-lg'>How long will the poster last?</p></AccordionHeading>
            <AccordionPanel><p className=' pb-5'>We pride ourselves in the quality of our paper. Our paper is made for museums and to last the test of history.</p></AccordionPanel>
          </AccordionSegment>
          <AccordionSegment>
            <AccordionHeading><p className='text-lg'>How do you protect my information?</p></AccordionHeading>
            <AccordionPanel><p className=' pb-5'>We protect your information by not collecting any credit card information. All credit card information is outsourced to a secure company called Stripe. Your email is collected in order to contact you and shipping information is used to ship packages.</p></AccordionPanel>
          </AccordionSegment>
          <AccordionSegment>
            <AccordionHeading><p className='text-lg'>Where can I go if my question wasn't answered?</p></AccordionHeading>
            <AccordionPanel><p className=' pb-5'>Contact us immediately at support@cosmokeepsakes.com. We are more than happy to help with any inquery or any problem!</p></AccordionPanel>
          </AccordionSegment>
        </Accordion>
      </div>
      {/* Contact */}
      <div className='w-11/12 max-w-[1280px] mx-auto pb-20 text-text'>
        <h2 className='pb-5 text-2xl font-semibold'>Still have questions?</h2>   
        <ContactUs />
      </div>

      {/* emial signup */}
      <div>

      </div>

      {/* Footer */}
      <Footer />
    </div>
  )
}