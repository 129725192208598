import emailSVG from './icons/email.svg'
import phoneSVG from './icons/phone.svg'
import mailboxSVG from './icons/mailbox.svg'

import { Navbar, Footer, ProductCard } from './StaticComponents'

import { Accordion, AccordionPanel, AccordionHeading, AccordionSegment } from './Accordion'

import ReactGA from "react-ga4";

export default function Contact() {
  ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact" });

  return (
    <div className=''>
      <Navbar background={'#9D90BE'} />

      <div className='py-32'>
        <ContactUs />
      </div>

      <div className='bg-whitegray'>
        <div className="flex flex-row w-11/12 max-w-[1280px] mx-auto py-32 text-text">
          <div className='w-1/2'>
            <div className='w-11/12 mx-auto'>
              <h2 className='text-2xl font-semibold'>Contact</h2>
              <p>Please feel free to reach out to us for any reason!</p>
              <h3 className='pt-8 text-xl font-semibold'>What is the best way to contact?</h3>
              <p>The most reliable way to contact us is through email at support@cosmokeepsakes.com.</p>
              <h3 className='pt-5 text-xl font-semibold'>How long should I expect for a response?</h3>
              <p>Expect a response from us within a business day, if not sooner.</p>
            </div>
          </div>
          <div className='w-1/2'>
            <div className='w-11/12 mx-auto'>
              <ProductCard />
            </div>
          </div>
        </div>
      </div>

      <div className='flex flex-row w-11/12 max-w-[1280px] mx-auto py-36'>
        <div className='w-1/2'>
          <div className='w-11/12 mx-auto'>
            <ShippingFAQ />
          </div>
        </div>
        <div className='w-1/2'>
          <div className='w-11/12 mx-auto'>
            <PrivacyFAQ />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export function ContactUs() {
  return (
    <div className="w-11/12 max-w-[1280px] mx-auto text-center">
      <h2 className="text-3xl text-lightblack font-bold mb-6">Contact Us</h2>
      <div className="flex flex-col tablet:flex-row tablet:flex-wrap justify-center">
        <div className="tablet:w-1/3 p-4">
          <img src={emailSVG} className="mx-auto w-20 h-20 mb-4" alt="High Quality" />
          <h3 className="text-2xl font-semibold">Email Us</h3>
          <p className="text-xl text-stripegray">support@cosmokeepsakes.com</p>
        </div>
        <div className="tablet:w-1/3 p-4">
          <img src={phoneSVG} className="mx-auto w-20 h-20 mb-4" alt="Beautiful Packaging" />
          <h3 className="text-2xl font-semibold">Call Us</h3>
          <p className="text-xl text-stripegray">267-792-2240</p>
        </div>
        <div className="tablet:w-1/3 p-4">
          <img src={mailboxSVG} className="mx-auto w-20 h-20 mb-4" alt="Personalization" />
          <h3 className="text-2xl font-semibold">Visit Us</h3>
          <p className="text-xl text-stripegray">436 N Main St Doylestown</p>
        </div>
      </div>
    </div>
  )
}

function ShippingFAQ() {
  return (
    <div>
      <h2 className='text-2xl font-semibold'>Shipping FAQ</h2>
      <Accordion>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>How much is shipping?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>Shipping is free for everyone! Anywhere, anytime!</p>
          </AccordionPanel>
        </AccordionSegment>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>How long does shipping take?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>Depends on the country, in most first world countries expect shipment within a week. You will recieve the most accurate time estimate at checkout. This is due to your order needing to be recieved, then individually produced, and then shipped.</p>
          </AccordionPanel>
        </AccordionSegment>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>Is there anywhere you do not ship?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>Nope! We ship internationally.</p>
          </AccordionPanel>
        </AccordionSegment>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>How will my order be packaged?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>Depending on your country, your order will be packaged either in a flat packaged, a tube, or a tueb with shipping roll. Any method is designed to keep your order safe and untouched in the shipping process.</p>
          </AccordionPanel>
        </AccordionSegment>
      </Accordion>
    </div>
  )
}
function ContactFAQ() {
  return (
    <div>
      <h2 className='text-2xl font-semibold'>Contact FAQ</h2>
      <Accordion>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>What is the best way to contact?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>The most reliable way to contact us is through email at help@cosmokeepsakes.com.</p>
          </AccordionPanel>
        </AccordionSegment>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>What is the average response time?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>Expect a response within 24 hours.</p>
          </AccordionPanel>
        </AccordionSegment>
      </Accordion>
    </div>
  )
}
function PrivacyFAQ() {
  return (
    <div>
      <h2 className='text-2xl font-semibold'>Privacy FAQ</h2>
      <Accordion>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>What information do you collect?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>
              We collect the minimum amout of information required to produce, ship, and contact. This includes: the information to create your star map, an email to contact, and an address to ship to.
              We do not collect credit card information, this is outsourced to a secure payment company, Stripe. 
            </p>
          </AccordionPanel>
        </AccordionSegment>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>How can I have my data deleted?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>Contact us and we will manually delete all information related to you.</p>
          </AccordionPanel>
        </AccordionSegment>
        <AccordionSegment>
          <AccordionHeading>
            <p className='text-xl'>How secure is your payment?</p>
          </AccordionHeading>
          <AccordionPanel>
            <p className='pb-5'>All payments are outsourced and handled by a company called Stripe. In exchange for keeping your information safe they keep 2% of every payment. In other words we do not collect any credit card information.</p>
          </AccordionPanel>
        </AccordionSegment>
      </Accordion>
    </div>
  )
}