import { useEffect } from "react";
import { Logo } from "./StaticComponents";

import ReactGA from "react-ga4";

const prices = {
  "12x16": 4999,
  "18x24": 5999,
  "24x32": 7499,
  "30x40": 7999,
}

export default function SuccessPage() { 
  ReactGA.send({ hitType: "pageview", page: "/success", title: "Success" });

  const caclulateSubtotal = () => {
    let products = JSON.parse(localStorage.getItem('map'))

    let sum = 0
    for(let i = 0; i < products.length; i++) {
      sum += prices[products[i]['size']] * products[i]['quantity']
    }
    return sum
  }

  useEffect(() => {
    const map = localStorage.getItem('map');
    if (map) {
      window.fbq('track', 'Purchase', {
        value: parseFloat((caclulateSubtotal/100).toFixed(2)),
        currency: 'USD',
      });

      localStorage.removeItem('map');
    }
  }, []);

  return (
    <div className="flex flex-col justify-center items-center w-screen h-screen">
      <Logo width={300} height={300} />
      <h1 className="text-5xl text-center text-[#4BB543] font-semibold">Success!</h1>
      <p className=" text-stripegray">Check your email for receipt</p>
      <p className="underline text-stripegray"><a href="/trackorder">Track Your Order</a></p>
    </div>
  )
}