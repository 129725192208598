import logo from '../logo.svg'

import factory from './factory.svg'
import rocket from './rocket.svg'
import mailbox from './mailbox.svg'
import error from './error.svg'
import magnifyingGlass from './magnifyingglass.svg'
import { useState } from 'react'

import axios from 'axios'

import ReactGA from "react-ga4";

export default function TrackOrder() {
  ReactGA.send({ hitType: "pageview", page: "/trackorder", title: "Track Order" });

  const [orderID, setOrderID] = useState('')
  const [status, setStatus] = useState('na')

  const getStatus = async () => {
    try {
      const response = await axios.post('https://server-e4ys6pw2bq-uc.a.run.app/get-status', { id: orderID });
      setStatus(response.data.status)
    } catch (error) {
      console.log('Error fetching status:', error);
    }
  }

  

  return (
    <div>
      {
        status === 'na' ? (
          <SearchOrder orderID={orderID} setOrderID={setOrderID} getStatus={getStatus} />
        ) : status === 'production' ? (
          <Production />
        ) : status === 'transit' ? (
          <Transit />
        ) : status === 'delivered' ? (
          <Delivered />
        ) : status === 'unfound' ? (
          <Unfound />
        ) : status === 'unrecognized' ? (
          <Error />
        ) : null
      }
    </div>
  )
}

function SearchOrder({orderID, setOrderID, getStatus}) {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <img className='w-1/4 max-w-[100px]' src={logo} alt='logo' />
      <h1 className="text-center text-lightblack text-xl font-semibold">Track Your Order</h1>
      <p className="text-center text-stripegray">You can find the Order ID in your email</p>
      <input className="w-11/12 max-w-[400px] mt-2 px-4 py-2 rounded-lg border border-lightblack text-lightblack" type="text" value={orderID} onChange={(e) => {setOrderID(e.target.value)}} placeholder="Order ID (pi_abc123...)" />
      <button className='underline' onClick={getStatus}>Track</button>
    </div>
  )
}

function Production() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <h2 className="text-center text-lightblack text-xl font-semibold">In Production</h2>
      <p className="text-center text-stripegray">Your order is currently being created!</p>
      <img className='w-1/4 max-w-[100px]' src={factory} />
      <Links />
    </div>
  )
}
function Transit() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <h2 className="text-center text-lightblack text-xl font-semibold">In Transit</h2>
      <p className="text-center text-stripegray">Your order is currently being shipped!</p>
      <img className='w-1/4 max-w-[100px]' src={rocket} />
      <Links />
    </div>  
  )
}
function Delivered() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <h2 className="text-center text-lightblack text-xl font-semibold">Delivered</h2>
      <p className="text-center text-stripegray">Your order has been delivered!</p>
      <img className='w-1/4 max-w-[100px]' src={mailbox} />
      <Links />
    </div>  
  )
}
function Unfound() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <h2 className="text-center text-lightblack text-xl font-semibold">Unfound</h2>
      <p className="text-center text-wrap text-stripegray">No order was found, please double check you entered your ID correctly.</p>
      <p className="text-center text-wrap text-stripegray">If you think this is a mistake please contact us.</p>
      <img className='w-1/4 max-w-[100px]' src={magnifyingGlass} />
      <Links />
    </div>  
  ) 
}
function Error() {
  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen">
      <h2 className="text-center text-lightblack text-xl font-semibold">Error</h2>
      <p className="text-center text-stripegray">Something went wrong, please contact us for more info.</p>
      <img className='w-1/4 max-w-[100px]' src={error} />
      <Links />
    </div>  
  ) 
}

function Links() {
  return (
    <div>
      <p className="underline text-stripegray text-center"><a href="/trackorder">Enter Again</a></p>
      <p className="underline text-stripegray text-center"><a href="/trackorder">Home</a></p>
    </div>
  )
}