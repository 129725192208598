function dateToDateObj(date) {
  // date is a string YYYY-mm-DDTHH:MM
  const datetime = date.split('T')
  const dates = datetime[0].split('-')
  const times = datetime[1].split(':')
  return new Date(dates[0], dates[1] - 1, dates[2], times[0], times[1])
}

function dateObjToDate(dateObj) {
  let month
  if(parseInt(dateObj.getMonth() + 1) < 10) { month = (`0${dateObj.getMonth() + 1}`)} else { month = dateObj.getMonth() + 1}
  let day
  if(dateObj.getDate() < 10) { day = (`0${dateObj.getDate()}`)} else { day = dateObj.getDate()}
  let hour
  if(dateObj.getHours() < 10) { hour = (`0${dateObj.getHours()}`)} else { hour = dateObj.getHours()}
  let minute
  if(dateObj.getMinutes() < 10) { minute = (`0${dateObj.getMinutes()}`)} else { minute = dateObj.getMinutes()}
  return (`${dateObj.getFullYear()}-${month}-${day}T${hour}:${minute}`)
}

function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}

function findMostRecentLeapYear(currentYear) {
  while (!isLeapYear(currentYear)) {
      currentYear--;
  }
  return currentYear;
}

function secondsSinceJ2000(dateTime) {
  // January 1st, 2000 at noon
  const J2000 = new Date(2000, 0, 1, 12, 0, 0);

  // Calculate the difference in time (milliseconds)
  const timeDifference = dateTime - J2000;

  // Convert the time difference from milliseconds to seconds
  const secondsDifference = Math.floor(timeDifference / 1000);

  return secondsDifference;
}


export {
  dateToDateObj,
  dateObjToDate,
  secondsSinceJ2000
}